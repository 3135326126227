#root {
    height: 100%;
    overflow-x: hidden;
}

#top-hero {
    background-image: url(./images/LongBkImg.png);
    /*height: 100vh;*/
    background-repeat: repeat;
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding-bottom: 20px;
}

#top-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top-hero-button {
    background: #434d5f;
    color: white;
    font-weight: 600;
    padding: 5px;
    font-size: 20px;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    #top-hero {
        background-size: 100vw;
        background-position: 100% 250px;
        background-attachment: static;
    }
}

@media only screen and (min-width: 768px) {
    #top-hero {
        background-size: 65vw;
        background-attachment: fixed;
        background-position: 100% 50px;
    }
}


#top-hero-callout {
    mix-blend-mode: overlay;
    color: white;
    font-weight: 600;
    display: inline-block;
    align-items: center;
}


@media only screen and (max-width: 767px) {
    #top-hero-callout {
        font-size: 14vw;
    }
}

@media only screen and (min-width: 768px) {
    #top-hero-callout {
        font-size: 6vw;
    }
}

#top-hero-top-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 60%;
    min-height: 500px;
}

#top-hero-demo-image {
    width: 70vw;
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
}


@media only screen and (max-width: 767px) {
    #top-hero-demo-image {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    #top-hero-demo-image {
        display: block;
    }
}

#top-hero-demo-image-phone {
    border-radius: 10px;
    width: 50vw;
    border: 2px solid #262626;
}


@media only screen and (max-width: 767px) {
    #top-hero-demo-image-phone {
        display: block;
    }
}

@media only screen and (min-width: 768px) {
    #top-hero-demo-image-phone {
        display: none;
    }
}

#top-hero-action-button {
    border-radius: 3px;
    padding: 8px 12px;
    font-weight: 600;
    margin-top: 25px;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    margin-right: 20px;
}

.primary {
    background: #eaedad;
    color: #434d5f;
}

.secondary {
    color: #434d5f;
    background: #c4efff;
}


#top-hero-top-part {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

#top-hero-title-desktop {
    color: white;
    font-weight: 600;
    font-size: min(40px, 2.5vw);
    margin-top: 10px;
}

@media only screen and (max-width: 767px) {
    #top-hero-title-desktop {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    #top-hero-title-desktop {
        display: block;
    }
}

