a {
    text-decoration: none;
}

*::-webkit-scrollbar {
  display: none;
}

div {
    overflow-x: hidden !important;
}

html {
    height: 100%;
    overflow: hidden;
    background: #f2f2f2;
}

h1, h2, h3, p, span, div {
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
}

body {
    height: 100%;
    overflow-y: scroll;
    background: #f2f2f2;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}


