h1 {
    font-size: 10px;
}

.brown {
    color: rgb(185, 114, 96);
}

.blue {
    color: rgb(121, 148, 204);
}

.green {
    color: rgb(119, 166, 153);
}

.red {
    color: rgb(243, 114, 114);
}

.yellow {
    color: rgb(221, 185, 113);
}


.invbrown {
    background: rgb(185, 114, 96);
    color: #fafafa;
    display: inline-block;
}

.invblue {
    background: rgb(121, 148, 204);
    color: #fafafa;
    display: inline-block;
}

.invgreen {
    background: rgb(119, 166, 153);
    color: #fafafa;
    display: inline-block;
}

.invred {
    background: rgb(243, 114, 114);
    color: #fafafa;
    display: inline-block;
}

.invyellow {
    background: rgb(221, 185, 113);
    color: #fafafa;
    display: inline-block;
}

#story {
    position:static;
    background: #f2f2f2;
    width: 100%;
    margin: max(3vw, 10px) 0;
}

.chapter {
    margin: 0 auto;
    margin-bottom: 15vh;
}

.story-callout {
    margin-left: 5px;
    padding: 5px 20px;
    display: inline-block;
}

.story-header {
    margin: 0;
    padding: 0;

    margin-top: 20px;
    margin-left: 5px;
    padding: 5px 20px;

    word-break: break-all;

    display: inline-block;

    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    .story-header {
        font-size: 24px;
    }
}

@media only screen and (min-width: 768px) {
    .story-header {
        font-size: 30px;
    }
}


.story-image {
    margin-top: 10px;
    display: block;
}

@media only screen and (max-width: 767px) {
    .story-image {
        width: 100vw;
    }
}

@media only screen and (min-width: 768px) {
    .story-image {
        width: 60vw;
    }
}


.image-annotation {
    position: absolute;
    top: 0%;
    transform: translateY(50vh);
    z-index: 10;
    font-weight: 600;
    margin-left: 20px;
    color: #434d5f;
    background: #eaedad;
    box-shadow: 5px 10px 10px #888888;
}


@media only screen and (max-width: 767px) {
    .image-annotation {
        font-size: 20px;
        padding: 5px;
    }
}

@media only screen and (min-width: 768px) {
    .image-annotation {
        font-size: 30px;
        padding: 10px;
    }
}

.story-description {
    font-weight: 600;
    margin: 0 auto;
    text-align: center;
}


@media only screen and (max-width: 767px) {
    .story-description {
        font-size: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .story-description {
        font-size: 40px;
    }
}

.right {
    float: right;
    margin-right: 60px;
    margin-left: 0 !important;
}

