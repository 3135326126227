#nav {
    background-color: rgba(255, 255, 255, 0.75);
    height: 55px;
    position:fixed;
    width: 100%;
    padding: 1px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    margin: 0 !important;
    backdrop-filter: blur(5px) contrast(.8);
}

#nav-logo {
    width: 40px;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
    display: inline-block;
}

#nav-name {
    display: inline-block;
    color: #434d5f;
    font-weight: 600;
    font-size: 20px;
    margin-left: 8px;
}

#nav-left {
    display: flex;
    align-items: center;
    margin-left: 1vw;
}

#nav-right {
    margin-right: 25px;
    text-align:right;
}

#nav-action {
    cursor: pointer;
    font-weight: 600;
    color: #434d5f;
    padding: 5px 7px;
    border-radius: 4px;
}

